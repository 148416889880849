<template>
  <!-- 应收计费 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <el-button class="itemBtn btnColor" @click="financeExamineBtn(0)"
          >审核</el-button
        >
        <el-button
          v-if="hasPermiss('ysgl_yingShouJiFei:examine:fan')"
          class="itemBtn btnColor"
          @click="financeExamineBtn(1)"
          >返审核</el-button
        >
        <el-button
          v-if="hasPermiss('ysgl_yingShouJiFei:update')"
          class="itemBtn btnColor"
          @click="editFinance"
          >费项修改</el-button
        >
        <!-- <el-button
        v-if="hasPermiss('ysgl_yingShouJiFei:update')"
        class="itemBtn btnColor"
        @click="editFinance"
        >查看打包</el-button
      > -->

        <!-- <el-button class="itemBtn btnColor">费用重算</el-button>
      <el-button class="itemBtn btnColor">费项修改</el-button>
      <el-button class="itemBtn btnColor">催款</el-button>
      <el-button class="itemBtn btnColor">催款</el-button>-->
        <el-button class="itemBtn btnColor" @click="myModel"
          >列表设置</el-button
        >
        <el-dropdown @command="handleCommand_dc">
          <el-button class="dropdownBtn">
            导出
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dc">导出</el-dropdown-item>
            <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
            <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class="singleinpbtn wtl_queryBoxNew"
        v-if="hasPermiss('ysgl_yingShouJiFei:query')"
      >
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询</el-button
        >
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">{{ MoreConditions }}</div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <multitime
            :timeType="timeType"
            :timeTypes="timeTypes"
            :startTime="startTime_a"
            :endTime="endTime_a"
            @timestatus="timestatus"
            @queryBtn_ok="queryBtn_time"
          >
          </multitime>
          <div class="singleinp">
            <div class="singleinpleft">来源单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.documentNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">申请打包编号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.billPackNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">核销状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.writeoffStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in writeoffStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">审核状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.approveStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in approveStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberId"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">仓库:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.storageId"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in WarehouseList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">结算对象类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.objectStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in objectStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">单据类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.documentStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in documentStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- <div class="singleinp">
            <div class="singleinpleft">起始日期:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.approveStartTime"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">结束日期:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.approveEndTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
            </div>
          </div> -->
        </div>
        <!-- <div class="item_left" v-if="hasPermiss('ysgl_yingShouJiFei:query')">
          <div class="singleinpbtn" v-if="hasPermiss('ysgl_yingShouJiFei:query')">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">{{ MoreConditions }}</div>
            </el-tooltip>
          </div>
        </div> -->
      </div>
    </el-form>

    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        ref="mytable"
        @row-click="rowclick"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        border
        :header-cell-style="{ background: '#f0f0f0' }"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          label="序号"
          type="index"
          width="60"
        ></el-table-column>
        <el-table-column
          fixed="left"
          type="selection"
          width="60"
        ></el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            :width="item.width"
            sortable
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          ></el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <!-- 自定义表头排序 -->
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import sorttable from "@/components/sortTemplate.vue";
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import multitime from "@/components/multiTime.vue";

// import { axios_file } from "@/assets/js/http_axios";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
    multitime,
  },
  data() {
    return {
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      queryData: {
        billPackNumber: null, //打包申请编号
        // approveEndTime: null,
        // approveStartTime: null,
        approveStatus: "shen_pi:approve_status", //审核状态
        // approverUserId: null, //审核人id
        documentNumber: null, //单据编号
        documentStatus: "",
        memberId: "", //会员号
        objectStatus: "", //结算对象类型
        storageId: "",
        writeoffStatus: "",
      },
      // ======公用组件==========
      pageNum: 1, //页码
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "单据类型",
          field: "documentStatusShow",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "单据编号",
          field: "documentNumber",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请打包编号",
          field: "billPackNumber",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "仓库名称",
          field: "storageName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        // {
        //   name: "会员名称",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        {
          name: "会员号",
          field: "memberId",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        // {
        //   name: "团号",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        {
          name: "结算金额",
          field: "settlementAmount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核状态",
          field: "approveStatusShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        // {
        //   name: "会员余额",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        // {
        //   name: "渠道",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        // {
        //   name: "渠道代理",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        // {
        //   name: "转单号",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        // {
        //   name: "重量",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        // {
        //   name: "体积",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        // {
        //   name: "件数",
        //   field: "",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        {
          name: "备注",
          field: "comment",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "创建时间",
          field: "createTime",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "objectUserName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核时间",
          field: "approveTime",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核人",
          field: "approverUserName",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "核销状态",
          field: "writeoffStatusShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "单据类型(值,不展示)",
          field: "documentStatus",
          width: "160",
          sort: 2,
          isShow: false,
          isTotal: false,
          isSort: true,
        },

        {
          name: "操作时间",
          field: "operationTime",
          width: "160",
          sort: 2,
          isShow: false,
          isTotal: false,
          isSort: true,
        },

        {
          name: "结算对象类型",
          field: "objectStatusShow",
          width: "120",
          sort: 4,
          isShow: false,
          isTotal: false,
          isSort: true,
        },

        {
          name: "操作人",
          field: "operatorUserName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "yingShouJiFe", //页面标识

      writeoffStatusList: [], //核销状态
      approveStatusList: [], //审核状态类别
      WarehouseList: [], //仓库下拉列表
      objectStatusList: [], //结算对象类型
      documentStatusList: [], //单据类型
      currentSelectData: [], //选中的数据
      ids: [], //选中的id数据

      // 时间组件
      timeTypes: [
        //  时间类型
        {
          value: "0",
          label: "审核时间",
        },
      ],
      timeType: "0", //  时间类型
      startTime_a: "",
      endTime_a: "",
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.endTime_a = tools.getCurrentDate(); //今天的时间;
    this.startTime_a = tools.getFlexDate(-10); //10天前的时间
    // this.queryData.approveEndTime = tools.getCurrentDate(); //今天的时间;
    // this.queryData.approveStartTime = tools.getFlexDate(-10); //10天前的时间

    this.getStorageList();
    this.getStatusValList("finance_in_record_v1.approve_status"); //审核状态
    this.getStatusValList("finance_in_record_v1.object_status"); //结算对象类型
    this.getStatusValList("finance_in_record_v1.document_status"); //单据类型
    this.getStatusValList("finance_in_record_v1.writeoff_status"); //核销状态
  },
  mounted() {
    const that = this;
    this.getData();
  },
  watch: {},
  methods: {
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 时间组件方法
    queryBtn_time(s, e) {
      this.startTime_a = s || "";
      this.endTime_a = e || "";
    },
    timestatus(type, s, e) {
      this.timeType = type || "0";
      this.startTime_a = s || "";
      this.endTime_a = e || "";
    },
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      var dataArr = [];
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 审核状态
        if (status == "finance_in_record_v1.approve_status") {
          let dataArr = res.data.result || [];
          this.approveStatusList = dataArr;
        }
        // 结算对象类型
        if (status == "finance_in_record_v1.object_status") {
          let objectStatusList = res.data.result || [];
          this.objectStatusList = objectStatusList;
        }
        // 单价类型
        if (status == "finance_in_record_v1.document_status") {
          let documentStatusList = res.data.result || [];
          this.documentStatusList = documentStatusList;
        }
        // 核销状态
        if (status == "finance_in_record_v1.writeoff_status") {
          let writeoffStatusList = res.data.result || [];
          this.writeoffStatusList = writeoffStatusList;
        }
      });
    },
    // //获取仓库下拉列表
    getStorageList() {
      Api.getStorageList().then((res) => {
        //////console.log(res.data.result)
        let cangkuList = res.data.result || [];
        this.WarehouseList = cangkuList;
      });
    },
    // 费项修改
    editFinance() {
      // documentStatus
      if (this.currentSelectData.length > 1) {
        this.$message.warning("一次只能修改一条数据哦~");
        return;
      } else if (this.currentSelectData.length <= 0) {
        this.$message.warning("您还没有选择数据哦~");
        return;
      }

      // approveStatus: "shen_pi:approve_status"
      let data = this.currentSelectData[0];
      //只有审批中的可以修改费项
      if (data.approveStatus !== "shen_pi:approve_status") {
        this.$message.warning("只有审批中的可以修改费项~");
        return;
      }

      //仓库打包单据
      if (data.documentStatus == "bill_storepack_v1:document_status") {
        console.log("仓库打包单据");
        this.$router.push({
          path: "/Finance/modifyFee",
          query: {
            pageType: "newpage",
            documentStatus: data.documentStatus,
            financeInId: data.financeInId,
          },
        });
      } else {
        this.$message.warning("只支持修改仓库打包单哦!");
      }

      // //申请验货单
      // if (data.documentStatus == "bill_inspection_v1:document_status") {
      //   console.log("申请验货单");
      //   Api.getFinanceInChargingInspectionDetail(param).then((res) => {});
      // }
      // //其他单据
      // if (data.documentStatus == "finance_documentstatus_v1:document_status") {
      //   console.log("其他单据");
      // }
      // //拒收单据
      // if (data.documentStatus == "bill_rejection_v1:document_status") {
      //   console.log("拒收单据");

      //   Api.getFinanceInChargingRejectionDetail(param).then((res) => {});
      // }
      // //充值转账单据
      // if (data.documentStatus == "bill_transfer_v1:document_status") {
      //   console.log("充值转账单据");
      // }

      // //包裹异常单据
      // if (data.documentStatus == "bill_package_exception_v1:document_status") {
      //   console.log("包裹异常单据");
      // }
    },
    //审核/反审核
    financeExamineBtn(a) {
      let examine = a; //0=审核,1=返审核
      console.log(examine);
      var arr = [];
      for (var i = 0; i < this.currentSelectData.length; i++) {
        arr.push(this.currentSelectData[i].financeInId);
      }
      this.ids = arr;
      let ids = this.ids;
      if (ids.length == 0) {
        this.$message.warning("您还没选择数据~");
        return;
      }

      var str = "审核";
      if (examine == 1) {
        str = "返审核";
      }
      this.$confirm("是否" + str + "选中的项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let param = {
            examine: examine,
            ids: ids, //应收计费id  多个
          };
          Api.financeExamine(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.getData();
            }
          });
        })
        .catch(() => {});
    },

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表
      let pagsHeigth = 40; //分页组件
      let heardHeight = 60; //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight;
      return Math.floor(windonHeight - otherHeight - 10) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      console.log("处理前", this.queryData.billPackNumber);
      //   let formData = this.queryData;
      let that = this;
      let dN = this.queryData.documentNumber || null;
      if (dN) {
        dN = tools.getOrderNum(dN);
        ////console.log(dN);
        // dN = JSON.stringify(dN);
      }

      // 处理申请打包编号为数组
      let bK = this.queryData.billPackNumber || null;
      if (bK) {
        console.log(bK, "======================**");
        bK = tools.getOrderNum(bK);
        // dN = JSON.stringify(dN);
      }
      console.log(bK, "=================");
      let endTimes = "";
      let startTimes = "";
      if (that.endTime_a) {
        endTimes = that.endTime_a + " " + "23:59:59";
      }
      if (that.startTime_a) {
        startTimes = that.startTime_a + " " + "00:00:00";
      }

      let formData = {
        billPackNumber: bK, //申请打包编号
        pageStart: that.pageNum, //页码
        pageTotal: that.size, //每页条数
        approveEndTime: endTimes, //结束审核时间
        approveStartTime: startTimes, //起始审核时间
        approveStatus: that.queryData.approveStatus, //审批类型 shen_pi:approve_status=审批中 ju_jue:approve_status=审批拒绝 tong_guo:approve_status=审批通过
        documentNumber: dN, //单据编号(表的编号)
        documentStatus: that.queryData.documentStatus, //单据类型(<表名>:document_status)
        memberId: that.queryData.memberId, //会员号
        objectStatus: that.queryData.objectStatus, //结算对象类型 hui_yuan:object_status=会员对象
        storageId: that.queryData.storageId, //仓库id
        // approverUserId: that.queryData.approverUserId, //审核人id
        writeoffStatus: that.queryData.writeoffStatus, //核销状态 wei_he_xiao:writeoff_status=未核销 yi_he_xiao:writeoff_status=已核销
      };

      Api.getFinanceInChargingList(formData).then((res) => {
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          that.tableData = res.data.result.data || [];
          that.total = res.data.result.pageCount || 0;

          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        approveEndTime: "",
        approveStartTime: "",
        approveStatus: "", //审核状态
        // approverUserId: '', //审核人id
        documentNumber: "", //单据编号
        billPackNumber: "", //打包申请编号
        documentStatus: "",
        memberId: "", //会员号
        objectStatus: "", //结算对象类型
        storageId: "",
        writeoffStatus: "",
      };
      this.startTime_a = "";
      this.endTime_a = "";
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      ////console.log(e);
      this.currentSelectData = e || [];
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  // return prev + curr;
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1;
      this.getData();
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.HeadInputbox .item_right .singleinp {
  ::v-deep {
    .el-date-editor.el-input {
      width: 100%;
    }
  }
}
</style>
